<template>
  <div class="light-layout">
    <WidgetHeader @close-widget="toggleCloseConfirmModal" />

    <div class="widget-base">
      <WidgetContentArea
        class="widget-base__content-area"
        @is-widget-loaded="onWidgetLoaded"
      />

      <ZhDisclaimer
        v-if="flowDetails?.isZerohashPayoutStrategy()"
        skin="mobile"
      />
    </div>

    <WidgetFooter />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import WidgetFooter from '@/v1/components/WidgetFooter';
import WidgetContentArea from '@/v1/components/WidgetContentArea';
import WidgetHeader from '@/v1/components/WidgetHeader';
import { useIsTransactionInFinalStatus } from '@/v1/composables/transactionStatus';

import {
  shouldChangeAppearanceForError as showLeftSidebar,
  isErrorRoute as hideTransactionDetails,
  widgetWrapperAlignment,
} from '@/v1/composables/errorScreenAppearance';

import ZhDisclaimer from '../components/ZhDisclosure/ZhDisclaimer.vue';

export default {
  name: 'WidgetWrapper',

  components: {
    ZhDisclaimer,
    WidgetFooter,
    WidgetHeader,
    WidgetContentArea,

  },
  setup(props, { emit }) {
    const store = useStore();
    const toggleCloseConfirmModal = () => {
      emit('closeWidget');
    };
    const isTransactionInFinalStatus = useIsTransactionInFinalStatus();

    const flowDetails = computed(() => store.getters['transaction/flowDetails']);

    return {
      toggleCloseConfirmModal,

      isTransactionInFinalStatus,

      hideTransactionDetails,
      showLeftSidebar,
      widgetWrapperAlignment,
      flowDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.light-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
</style>
