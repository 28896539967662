const NS = {
  ADD: 'add',
  REMOVE: 'remove',
};

export default {
  namespaced: true,

  state: {
    notifications: [],
  },

  getters: {
    notifications: state => state.notifications,
  },

  mutations: {
    [NS.ADD]: (state, notification) => {
      if (state.notifications.length > 2) {
        state.notifications.splice(0, 1);
      }
      state.notifications.push(notification);
    },

    [NS.REMOVE]: (state, notification) => {
      state.notifications = state.notifications.filter(item => item !== notification);
    },
  },

  actions: {
    add({ commit }, message) {
      commit(NS.ADD, { message });
    },

    remove({ commit }, notification) {
      commit(NS.REMOVE, notification);
    },
  },
};
