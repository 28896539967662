<template>
  <div :ref="widthDetector" />
  <Transition name="toast">
    <div
      v-if="isPaymentFailedWithoutFallbackAvailable"
      class="widget-content-toast widget-content-toast--error"
      @click="onClose"
    >
      {{ label }}

      <div class="widget-content-toast__close btn-close">
        <span class="svg-icon icon-close-red widget-content-toast__close-icon" />
      </div>
    </div>
  </Transition>
  <div class="toast-wrapper">
    <TransitionGroup name="toast">
      <div
        v-for="notification in notifications"
        :key="notification.message"
        class="toast toast--error"
        @click="closeNotification(notification)"
      >
        {{ notification.message }}

        <div class="widget-content-toast__close btn-close">
          <span class="svg-icon icon-close-red widget-content-toast__close-icon" />
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useElementSize } from '@vueuse/core';

const MIN_TOAST_HEIGHT = 57;
const LINE_HEIGHT = 17;
const APPROXIMATED_SYMBOL_WIDTH = 12;

export default {
  name: 'WidgetContentToast',

  setup() {
    const store = useStore();
    const { t } = useI18n();

    const widthDetector = ref();

    const { width } = useElementSize(widthDetector.value);

    const isPaymentFailed = computed(() => store.getters['external/isPaymentFailed']);
    const showPaymentErrorCallout = computed(() => store.getters['paymentErrorPopup/showPaymentErrorCallout']);
    const isPaymentProcessorNotFound = computed(() => store.getters.isPaymentProcessorNotFound);
    const isBuyCryptoFlow = computed(() => store.getters.isBuyCryptoFlow);
    const payment = computed(() => store.getters['transaction/payment']);
    const selectedCard = computed(() => store.getters['card/selectedCard']);

    const cardDeclineMessagesFeature = computed(() => store.getters['feature/cardDeclineMessagesFeature']);
    const cardDeclineMessagesFeatureEnabled = computed(() => cardDeclineMessagesFeature.value.isEnabled());

    const isPaymentFailedWithoutFallbackAvailable = computed(() => {
      if (isBuyCryptoFlow.value) {
        return isPaymentFailed.value && !payment.value.isFallbackAvailable() && payment.value.isBankCard() && showPaymentErrorCallout.value;
      }
      return isPaymentFailed.value && showPaymentErrorCallout.value;
    });

    const cardPaymentDeclineCode = computed(() => {
      return selectedCard.value.getDeclineCardErrorCode();
    });

    const label = computed(() => {
      if (isPaymentProcessorNotFound.value) {
        return t('widget.errors.try-another-card');
      }

      if (cardDeclineMessagesFeatureEnabled.value) {
        return t(`transaction-flow.card-payment-decline-codes.bridger-pay.${cardPaymentDeclineCode.value}`);
      }

      return t('widget.errors.try-another-card');
    });

    const linesCount = computed(() => {
      const symbolsPerLine = width.value / APPROXIMATED_SYMBOL_WIDTH;

      return Math.round(label.value.length / symbolsPerLine);
    });

    const toastHeight = computed(() => {
      const calculated = LINE_HEIGHT * linesCount.value + (10 * 2);

      return `${MIN_TOAST_HEIGHT > calculated ? MIN_TOAST_HEIGHT : calculated}px`;
    });

    const resetFunnelCardErrorState = () => {
      store.dispatch('external/resetFunnelCardErrorState', null, { root: true });
    };

    const setShowPaymentErrorCallout = value => {
      store.dispatch('paymentErrorPopup/setShowPaymentErrorCallout', value, { root: true });
    };

    const onClose = () => {
      setShowPaymentErrorCallout(false);
      resetFunnelCardErrorState();
    };

    const notifications = computed(() => {
      const list = store.getters['notifications/notifications'];
      const lastNotification = list[list.length - 1];
      if (lastNotification) {
        return [lastNotification];
      }

      return [];
    });
    const closeNotification = notification => store.dispatch('notifications/remove', notification);

    return {
      widthDetector,
      isPaymentFailedWithoutFallbackAvailable,
      label,
      toastHeight,
      onClose,
      notifications,
      closeNotification,
    };
  },
};
</script>

<style
  lang="scss"
  scoped
>
$toast-y-padding: 10px;

@keyframes toast-slide-down {
  0% {
    height: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    height: v-bind(toastHeight);
    padding-top: $toast-y-padding;
    padding-bottom: $toast-y-padding;
  }
}

.toast-enter-active {
  animation: toast-slide-down .15s ease-out;
}

.toast-leave-active {
  animation: toast-slide-down .15s ease-out reverse;
}

.toast-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.toast {
  display: flex;
  padding: 10px 36px;
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.29;
  text-align: center;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  &--success {
    color: $green;
    background-color: rgba($green, .1);
  }

  &--error {
    color: $red;
    background-color: rgba($red, .1);
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;

    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  &__close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
}

.widget-content-toast {
  display: flex;
  padding: 10px 36px;
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.29;
  text-align: center;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: v-bind(toastHeight);

  &--success {
    color: $green;
    background-color: rgba($green, .1);
  }

  &--error {
    color: $red;
    background-color: rgba($red, .1);
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;

    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  &__close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
}
</style>
