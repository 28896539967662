<template>
  <div
    class="exchange-info"
    :class="{
      'exchange-info--mobile': isMobile,
      'exchange-info--sell': isSellCryptoFlow,
    }"
  >
    <div
      v-if="hasInvoice"
      class="exchange-info__invoice-id"
      data-testid="exchange-info-invoice-id"
      :class="{ 'invoice-id--light': lightLayout }"
    >
      {{ invoiceId }}
      <i
        class="exchange-info__invoice-icon svg-icon"
        data-testid="exchange-info-invoice-icon"
        :class="{
          'exchange-info__invoice-icon--check' : isCopied,
          'exchange-info__invoice-icon--copy' : !isCopied,
          'invoice-icon--light' : lightLayout
        }"
        @click="copyInvoiceId"
      />
    </div>
    <div
      v-if="hasWallet"
      class="exchange-info__wallet"
    >
      <ClampWalletAddress>
        {{ wallet }}
      </ClampWalletAddress>
    </div>

    <div
      v-if="hasTag"
      class="exchange-info__tag"
      data-testid="exchange-info-tag"
    >
      {{ $t('widget.wallet-tag') }}: {{ tag }}
    </div>

    <div
      ref="valueToRef"
      class="exchange-info__value-to"
      data-testid="exchange-info-value-to"
      :class="{ hidden: isQuoteHasError || !currencyCodeFrom }"
      :style="{ height: heightValueTo }"
    >
      <i
        v-if="isMobile"
        class="value-icon svg-icon"
        :class="`icon-${currencyIconName}`"
      />
      <span :class="!isSellCryptoFlow ? payoutAmountChangeClass : ''">
        <template v-if="isSellCryptoFlow">
          {{ amountFrom }} {{ currencyCodeFrom }}
        </template>
        <template v-else-if="isQuoteLoading">
          <Loader
            class="exchange-info__loader"
            size="100%"
          />
        </template>
        <template v-else>
          {{ currencyToLabel }}
        </template>
      </span>
    </div>

    <div
      v-if="isMobile && !isQuoteHasError && currencyCodeFrom"
      class="exchange-info__arrow"
    >
      <span class="value-icon svg-icon icon-arrow-long-down-black" />
    </div>

    <div
      class="exchange-info__value-from"
      :class="{ hidden: isQuoteHasError || !currencyCodeFrom }"
    >
      <img
        v-if="showAPMIcon"
        class="value-icon svg-icon"
        :src="fromCurrencyIconPath"
      >
      <i
        v-if="isCryptoAcquiring"
        class="value-icon svg-icon"
        :class="`icon-${fromCurrencyIconPath}`"
      />
      <span
        v-if="showCreditCardIcon"
        class="value-icon svg-icon icon-card"
      />

      <span
        v-if="!isMobile"
        class="exchange-info__for"
      >
        {{ $t('widget.for') }}
      </span>
      <span :class="isSellCryptoFlow ? payoutAmountChangeClass : ''">
        <template v-if="isSellCryptoFlow">
          {{ currencyToLabel }}
        </template>
        <template v-else>
          {{ amountFrom }} {{ currencyFrom }}
        </template>
      </span>

      {{ paymentMethodLabel && !isPaybisAcquireTheme && !lightLayout ? `${$t('widget.payment-methods.via')} ${paymentMethodLabel}` : '' }}
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import { useI18n } from 'vue-i18n';
import ClampWalletAddress from '@/v1/components/ClampWalletAddress';
import { apmCurrencyIcon } from '@/v1/composables/apmCurrencyIcon';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { getIconPath } from '@/utils/getMoneyServiceIconPath';
import { usePaymentMethodLabel } from './usePaymentMethodLabel';
import { isV2 } from '@/services/apiVersion';
import Loader from '@/v1/components/Loader';
import getParams from '@/v1/utils/getParams';
import { Layouts } from '@/constants/layouts';
import Decimal from 'decimal.js-light';

const SKINS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

// TODO: split this component on mobile/desctop presentations
export default {
  name: 'ExchangeRateInfo',

  components: {
    Loader,
    ClampWalletAddress,
  },

  props: {
    skin: {
      type: String,
      default: SKINS.DESKTOP,
      validator: value => [SKINS.DESKTOP, SKINS.MOBILE].indexOf(value) !== -1,
    },
  },

  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const toast = useToast();
    const isCopied = ref(false);
    const { paymentMethodLabel } = usePaymentMethodLabel();

    const { layout } = getParams();
    const lightLayout = computed(() => layout === Layouts.LIGHT);
    const showCreditCardIcon = computed(() => {
      if (isCryptoAcquiring.value) {
        return false;
      }
      return isMobile.value && !isApm.value;
    });

    const showAPMIcon = computed(() => {
      if (!isCryptoAcquiring.value) {
        return false;
      }
      return isMobile.value && isApm.value;
    });

    const payoutAmountChangeClass = computed(() => {
      const { originalAmount, amount } = store.getters['external/funnelQuote']?.buy || {};
      return {
        'text-green': Number(amount) > Number(originalAmount),
      };
    });

    const isApm = computed(isV2);

    const isMobile = computed(() => props.skin === SKINS.MOBILE);
    const amountTo = computed(() => store.getters['widgetQuote/amountToCropped'] || '0');
    const currencyTo = computed(() => {
      if (isCryptoAcquiring.value) {
        return store.getters['widgetQuote/isQuoteReady']
          ? store.getters['widgetQuote/currencyCodeTo']
          : store.getters['exchangeForm/toCurrency'];
      }

      let currency = store.getters['widgetQuote/currencyTo'];
      if (!store.getters['widgetQuote/isQuoteReady']) {
        currency = store.getters['exchangeForm/toCurrencyCode'];
      }

      return store.getters['exchangeForm/getCurrencyBy'](currency);
    });
    const wallet = computed(() => store.getters['external/transactionPayoutAccount']);
    const tag = computed(() => store.getters['external/transactionPayoutTag']);
    const hasTag = computed(() => tag.value !== null);
    const hasInvoice = computed(() => store.getters['request/hasInvoice']);
    const invoiceId = computed(() => store.getters['request/invoiceId']);
    const payout = computed(() => store.getters['transaction/payout']);
    const isQuoteHasError = computed(() => store.getters['v2/widgetQuote/hasError']);
    const isPaybisAcquireTheme = computed(() => store.getters.isPaybisAcquireTheme);

    const currencyIconName = computed(() => {
      if (isCryptoAcquiring.value) {
         return currencyTo.value?.toLowerCase();
      }

      if (isSellCryptoFlow.value) {
        return currencyFrom.value?.toLowerCase();
      }

      return currencyTo.value?.toLowerCase();
    });

    const fromCurrencyIconPath = computed(() => {
      if (isCryptoAcquiring.value) {
        return currencyFrom.value?.toLowerCase();
      }
      return apmCurrencyIcon.name && getIconPath(apmCurrencyIcon.name);
    });

    const hasWallet = computed(() => hasInvoice.value !== null && (wallet.value !== null && !payout.value.isBankCard()));
    const amountFrom = computed(() => {
      const amount = store.getters['widgetQuote/amountFrom'];

      if (!amount) {
        return '0.00';
      }

      if (isCryptoAcquiring.value) {
        const formattedAmount = new Decimal(amount).toFixed(8);
        return parseFloat(formattedAmount);
      }

      // XXX: in sell crypto, we should display up to last sign
      if (isSellCryptoFlow.value) {
        const [num, dec] = amount.split('.');

        if (dec) {
          const decimal = dec.replace(/^0*$/, '').replace(/^([0-9]*[1-9])0*$/, '$1') || '00';
          return `${num}.${decimal}`;
        }

        return `${num}.00`;
      }

      // XXX: need to make it a number, because we have different string calculations for different currencies
      return Number.parseFloat(amount).toFixed(2);
    });
    const currencyFrom = computed(() => (
      store.getters['widgetQuote/isQuoteReady']
        ? store.getters['widgetQuote/currencyCodeFrom']
        : store.getters['exchangeForm/fromCurrency']
    ));

    const currencyToLabel = computed(() => `${amountTo.value} ${currencyTo.value}`);
    const exchangeQuote = computed(() => store.getters['exchangeForm/quote']);
    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);
    const isCryptoAcquiring = computed(() => store.getters.isCryptoAcquiring);
    const isQuoteLoading = computed(() => {
      if (store.getters.isV2) {
        return store.getters['v2/widgetQuote/isQuoteLoading'];
      }
      return store.getters['widgetQuote/isQuoteLoading'];
    });
    const currencyCodeFrom = computed(() => store.getters['exchangeForm/cryptoAssets'][currencyFrom.value]?.currency ?? currencyFrom.value);

    const valueToRef = ref(null);
    const heightValueTo = ref(null);

    onMounted(() => {
      watch(isQuoteLoading, currentValue => {
        if (currentValue && valueToRef.value && valueToRef.value.clientHeight > 0) {
          heightValueTo.value = `${valueToRef.value.clientHeight}px`;
        } else {
          heightValueTo.value = null;
        }
      });
    });

    const copyInvoiceId = () => {
      if (!isCopied.value) {
        copyToClipboard(invoiceId.value).then(() => {
          isCopied.value = true;
          toast.success(t('widget.success-bufer-copy'), { position: 'top' });
        });

        setTimeout(() => {
          isCopied.value = false;
        }, 10000);
      }
    };

    return {
      payoutAmountChangeClass,
      exchangeQuote,
      isMobile,
      paymentMethodLabel,

      hasTag,
      tag,
      invoiceId,
      amountFrom,
      currencyFrom,

      currencyToLabel,

      wallet,
      hasWallet,

      hasInvoice,
      currencyIconName,
      fromCurrencyIconPath,

      isCopied,
      copyInvoiceId,

      isSellCryptoFlow,
      isCryptoAcquiring,
      isQuoteHasError,
      isPaybisAcquireTheme,
      lightLayout,
      apmCurrencyIcon,
      isQuoteLoading,
      valueToRef,
      heightValueTo,
      currencyCodeFrom,
      showCreditCardIcon,
      showAPMIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.exchange-info {
  max-width: rem(700);
  text-align: center;
  display: none;

  @media (min-width: $tablet-max) {
    width: 100%;
    max-width: none;
    margin-top: 0;

    display: flex;
    flex-direction: column;
  }

  &__invoice-id {
    $line-height: 36px;
    font-size: 1em;
    color: $grey;
    order: 5;
    height: auto;
    line-height: $line-height;

    @media (min-width: $tablet-max) {
      color: #fff;
      order: initial;
      font-size: 1.1em;
    }

    @media (min-width: $desktop-min) and (max-height: $widget-height-sm) {
      font-size: calcVh(18, $widget-h-sm);
      line-height: calcVh(36, $widget-h-sm);
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: calcVh(18, $widget-h-xlg);
      line-height: calcVh(36, $widget-h-xlg);
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: calcVh(18, $widget-h-xxlg);
      line-height: calcVh(36, $widget-h-xxlg);
    }
  }

  &__invoice-icon {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-left: 8px;

    &--copy, &--check{
      background-color: $white;
      mask-repeat: no-repeat;

      @media (max-width: $tablet-max) {
        background-color: $black;
      }
    }

    &--copy {
      mask-image: url(@/v1/assets/images/widget/copy-white.svg);
    }

    &--check {
      mask-image: url(@/v1/assets/images/widget/check-double-white.svg);
    }

    @media (max-width: $tablet-max) {
      color: $black;
    }
  }

  &__wallet,
  &__tag {
    font-size: rem(14);
    line-height: rem(24);
    color: rgba($white, 0.5);
  }

  &__wallet {
    @media (min-width: $laptop-min) {
      max-width: 28rem;
      margin: auto;
    }
  }

  &__value-to {
    font-size: 34px;
    line-height: rem(44);
    color: #fff;

    :deep(.loader .loader__header) {
      height: 100%;
      width: 100%;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      font-size: 4.43vh;
      line-height: 5.7vh;
    }

    @media (min-width: $desktop-min) {
      font-size: rem(36);
    }

    @media (min-width: $desktop-min) and (max-height: $widget-height-sm) {
      font-size: 4.69vh;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: 4.7vh;
      line-height: 5.74vh;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: 4.77vh;
      line-height: 5.83vh;
    }
  }

  &__value-from {
    font-size: 16px;
    line-height: 24px;
    color: #fff;

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      font-size: 2.1vh;
      line-height: 3.12vh;
    }

    @media (min-width: $desktop-min) {
      font-size: rem(18);
    }

    @media (min-width: $desktop-min) and (max-height: $widget-height-sm) {
      font-size: 2.34vh;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: 2.35vh;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: 2.39vh;
      line-height: 3.18vh;
    }
  }

  &__fees-details {
    display: none;
  }

  &__for {
    margin-right: 5px;
  }

  &--mobile {
    display: none;
    background: #f5f5f5;
    color: currentColor;
    margin: 0;

    .exchange-info__fees-details {
      display: flex;
      margin-bottom: 16px;
    }

    @media (max-width: $tablet-max) {
      display: flex;
      flex-direction: column;
      padding: 20px 1.5rem 0;
      text-align: center;
      :deep(.loader__spinner::after) {
        top: -35px;
        right: -50px;
      }
    }

    .exchange-info__wallet,
    .exchange-info__tag {
      color: $grey-30;
      font-size: 14px;
      line-height: 18px;
      margin-top: 4px;
      order: 4;
      opacity: 1;
    }

    .exchange-info__wallet {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .exchange-info__value-to,
    .exchange-info__value-from {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 8px;
      color: inherit;
      margin-top: 0;
    }

    .value-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      position: relative;
      top: 2px;
    }

    // Order of items on mobile devices
    .exchange-info__tag {
      order: 4;
    }

    .exchange-info__arrow {
      order: 2;
    }

    .exchange-info__value-from {
      order: 1;
    }

    .exchange-info__value-to {
      margin-top: 8px;
      order: 3;
    }

    &.exchange-info--sell {
      .exchange-info__value-from {
        margin-top: 8px;
        order: 3;
      }

      .exchange-info__value-to {
        margin-top: 0px;
        order: 1;
      }
    }
  }
}

.exchange-info__value-to.hidden,
.exchange-info__value-from.hidden {
  display: none;
};

.exchange-info .invoice-id--light {
  color: #b2b2b2;
  order: 5;
}
.exchange-info .invoice-icon--light {
  background-color: #000;
}
</style>
