import { ExpirationDateField, SaveField } from './card/field';
import { CardAuthorizationStatus } from './card/status';
import Field from './field';
import BillingAddress from './billingAddress';
import Storage from '@/v1/packages/common/services/storage';
import store from '@/v1/store';

class Card {
  constructor(id = 'new', status = null) {
    this.id = id;
    this.status = new CardAuthStatus(status);
    this.paymentId = null;
    this.cvv = null;
    this.cvvRequired = true;
    this.cvvError = false;
    this.cvvErrorMessage = null;
    this.type = '';
    this.error = false;
    this.verificationError = false;
    this.alreadyVerifying = false;
    this.applePayError = false;
    this.googlePayError = false;
    this.declineCardErrorCode = null;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this.id = id;
  }

  isNew() {
    return this.id === 'new';
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this.type = type;
  }

  getStatus() {
    return this.status;
  }

  hasPaymentId() {
    return this.paymentId !== null;
  }

  getPaymentId() {
    return this.paymentId;
  }

  setPaymentId(paymentId) {
    this.paymentId = paymentId;
  }

  getCvv() {
    return this.cvv;
  }

  setCvv(cvv) {
    this.cvv = `${cvv}`;
  }

  hasCvv() {
    return this.cvv !== null;
  }

  hasCvvError() {
    return !(this.hasCvv() && this.cvv.length === 3) && this.cvvError;
  }

  setCvvError() {
    this.cvvError = true;
  }

  clearCvvError() {
    this.cvvError = false;
    this.cvvErrorMessage = null;
    if (this.fields) {
      this.fields.cvv.setErrorMessage(null);
    }
  }

  getCvvErrorMessage() {
    return this.cvvErrorMessage;
  }

  setCvvErrorMessage(message) {
    this.cvvErrorMessage = message;
    if (this.fields) {
      this.fields.cvv.setErrorMessage(message);
    }
  }

  isCvvRequired() {
    return this.cvvRequired;
  }

  setCvvRequired(isRequired) {
    this.cvvRequired = isRequired;
  }

  setVerificationError() {
    this.verificationError = true;
  }

  hasVerificationError() {
    return this.verificationError;
  }

  setAlreadyVerifying() {
    this.alreadyVerifying = true;
  }

  isAlreadyVerifying() {
    return this.alreadyVerifying;
  }

  setError() {
    this.error = true;
  }

  hasError() {
    return this.error;
  }

  setDeclineCardErrorCode(errorCode) {
    this.declineCardErrorCode = errorCode;
  }

  getDeclineCardErrorCode() {
    const invoice = store.getters['transaction/invoice'];
    const code = this.declineCardErrorCode || Storage.get(`paymentErrorCode:${invoice}`);
    if (code) {
      return code.toLowerCase().replace(/_/g, '-');
    }
    return 'default';
  }

  setApplePayError() {
    this.applePayError = true;
  }

  hasApplePayError() {
    return this.applePayError;
  }

  setGooglePayError() {
    this.googlePayError = true;
  }

  hasGooglePayError() {
    return this.googlePayError;
  }

  removeErrors() {
    this.error = false;
    this.verificationError = false;
    this.alreadyVerifying = false;
    this.applePayError = false;
    this.googlePayError = false;
  }
}

export class CardItem extends Card {
  // eslint-disable-next-line camelcase
  constructor({
    id,
    masked_card_number,
    cardholder_name,
    is_expired,
    expiration_date,
    status,
  }) {
    super(id, status);
    // eslint-disable-next-line camelcase
    this.maskedCardNumber = masked_card_number;
    // eslint-disable-next-line camelcase
    this.cardholderName = cardholder_name;
    // eslint-disable-next-line camelcase
    this.expired = is_expired;
    // eslint-disable-next-line camelcase
    this.expirationDate = expiration_date;
  }

  getMaskedCardNumber() {
    return this.maskedCardNumber;
  }

  getCardholderName() {
    return this.cardholderName;
  }

  isExpired() {
    return this.expired;
  }

  getExpirationDate() {
    return this.expirationDate;
  }
}

export default class CardAuthStatus {
  constructor(status = null) {
    this.status = status;
    this.authPending = false;
  }

  getValue() {
    return this.status;
  }

  setStatus(status) {
    this.status = status;
  }

  isAuthPending() {
    return this.authPending;
  }

  startAuthPending() {
    this.authPending = true;
  }

  completeAuthPending() {
    this.authPending = false;
  }

  isAuthFailed() {
    return this.status === CardAuthorizationStatus.STATUS_AUTH_FAILED;
  }

  isAuthSuccess() {
    return this.status === CardAuthorizationStatus.STATUS_AUTH_SUCCESS;
  }

  isDuplicate() {
    return this.status === 'duplicate';
  }
}

export class AddCard extends Card {
  constructor() {
    super();
    this.fields = {
      cardNumber: new Field({
        id: 'card-number',
        name: 'card_number',
        type: 'text',
        value: '',
      }),
      cardholderName: new Field({
        id: 'cardholder-name',
        name: 'cardholder_name',
        type: 'text',
        value: '',
      }),
      expirationDate: new ExpirationDateField({
        id: 'expiration-date',
        name: 'expiration_date',
        type: 'text',
        value: '',
      }),
      cvv: new Field({
        id: 'cvv',
        name: 'cvv',
        type: 'text',
        value: '',
      }),
      save: new SaveField({
        id: 'save',
        name: 'save',
        type: 'checkbox',
        value: '',
      }),
    };

    this.billingAddress = new BillingAddress();
  }

  getFormFields() {
    return this.fields;
  }

  resetFormFields() {
    Object.values(this.fields).forEach(field => {
      field.setValue('');
    });
  }

  resetErrors() {
    Object.values(this.fields).forEach(field => {
      field.setErrorMessage(null);
    });
  }
}
