import EventNames from '../../models/eventNames';
import { SumsubConfig } from '../../models/sumsub';
import Verification from '../../models/verification';
import ProcessingClient from '../../services/clients/processing-client';

import eventBus from '../../helpers/eventBus';

const REVIEW_ANSWER_RED = 'RED';

export default {
  namespaced: true,
  state: {
    verification: new Verification(),
    baseVerification: new Verification(),
    sumsubConfig: new SumsubConfig(),
    isPendingScreenForcedForRejected: false, // when SumSub has GREEN answer but verification is still rejected
    lastReviewAnswer: null,
  },
  getters: {
    verification: state => state.verification,
    baseVerification: state => state.baseVerification,
    sumsubConfig: state => state.sumsubConfig,
    isPendingScreenForcedForRejected: state => state.isPendingScreenForcedForRejected,
    lastReviewAnswerIsRed: state => state.lastReviewAnswer === REVIEW_ANSWER_RED,
  },
  mutations: {
    setVerification(state, payload) {
      state.verification = payload;
    },
    resetVerification(state) {
      state.verification = new Verification();
    },
    setBaseVerification(state, payload) {
      eventBus.emit(
        EventNames.BASE_VERIFICATION_STATUS_CHANGED,
        payload.getStatusSlug(),
      );
      state.baseVerification = payload;
    },
    setsumsubConfig(state, payload) {
      state.sumsubConfig = payload;
    },
    forcePendingScreenForRejected(state) {
      state.isPendingScreenForcedForRejected = true;
    },
    unforcePendingScreenForRejected(state) {
      state.isPendingScreenForcedForRejected = false;
    },
    lastReviewAnswer(state, payload) {
      state.lastReviewAnswer = payload;
    },
  },
  actions: {
    getVerification({ commit }) {
      return ProcessingClient.getVerification().then(response => {
        const verification = new Verification(response.status);
        const baseVerification = new Verification(response.status);

        commit('setVerification', verification);
        commit('setBaseVerification', baseVerification);

        const sumsubConfig = new SumsubConfig(
          response.isSumsubLivenessEnabled,
          response.accessToken,
          response.levelName,
          response.apiUrl,
          response.email,
        );
        commit('setsumsubConfig', sumsubConfig);

        return verification;
      });
    },
    saveLastReviewAnswer({ commit }, lastReviewAnswer) {
      commit('lastReviewAnswer', lastReviewAnswer);
      ProcessingClient.saveReviewAnswer(lastReviewAnswer);
    },
  },
};
